<template>
    <AutoCompleteList
        name="topic"
        title="topic"
        key-ref="externalId"
        :label="`${label}: ${value.length}/${maxTopics}`"
        :errors="inputErrors"
        :selected-items="value"
        :items="topicSearchResult"
        :get-badge="getTopicBadge"
        :show-error-messages="true"
        @query="$emit('query', $event)"
        @update="$emit('input', $event)"
    />
</template>

<script>
import AutoCompleteList from '@/components/forms/AutoComplete/AutoCompleteList';

import { BadgeType } from '@/store/enums';
export default {
    components: {
        AutoCompleteList,
    },
    props: {
        label: {
            type: String,
            default: 'Topics',
        },
        value: {
            type: Array,
            default: () => [],
        },

        topicSearchResult: {
            type: Array,
            default: () => [],
        },
        maxTopics: {
            type: Number,
            default: 5,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        inputErrors() {
            const errors = [...this.errors];
            if (this.value.length > this.maxTopics) {
                errors.push(`Max. ${this.maxTopics} topics`);
            }
            return errors;
        },
    },
    methods: {
        getTopicBadge(topic) {
            if (topic?.id) {
                return null;
            }
            return { label: 'new', type: BadgeType.Neutral };
        },
    },
};
</script>
