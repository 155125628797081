<template>
    <div>
        <PageHeading :breadcrumbs="breadcrumbs" :title="title" />
        <FullPageLoadingSpinner v-if="loadingFlag" :show="$wait.is(loadingFlag)" />
        <slot />
        <Stepper
            show-next-button
            next-button-help=""
            :next-button-is-disabled="saveButtonDisabled"
            :next-button-text="saveButtonLabel"
            :is-loading="loadingStepper"
            @nextButtonOnClick="onSave"
        />
        <Dialog
            show-confirm-button
            :is-dialog-visible="confirmDialogIsOpen"
            :is-loading="loadingDialog"
            @onClose="onCloseModal"
            @onClickOutside="onCloseModal"
            @closeOnEscapeEvent="onCloseModal"
            @confirmButtonOnClick="onConfirmModal"
        >
            <template #header>
                <slot name="dialog-confirm-header"> Some changes have been made. </slot>
            </template>
            <template #body>
                <slot name="dialog-confirm-body"> Do you want to save these changes? </slot>
            </template>
        </Dialog>
    </div>
</template>

<script>
import PageHeading from '@/components/ui/PageHeading';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import Stepper from '@/components/ui/Stepper';
import Dialog from '@/components/ui/Dialog';
import { LoadingFlag } from '@/store/enums';

export default {
    name: 'ViewTemplate',
    components: {
        PageHeading,
        FullPageLoadingSpinner,
        Stepper,
        Dialog,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        saveButtonLabel: {
            type: String,
            default: 'Save',
        },
        saveButtonDisabled: {
            type: Boolean,
            default: false,
        },
        loadingFlag: {
            type: String,
            default: undefined,
            validator(value) {
                return Object.values(LoadingFlag).includes(value);
            },
        },
        loadingStepper: {
            type: Boolean,
            default: false,
        },
        loadingDialog: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            breadcrumbs: [],
            confirmDialogIsOpen: false,
        };
    },
    created() {
        this.setBreadcrumbs();
    },
    methods: {
        setBreadcrumbs() {
            const breadcrumbs = [];
            this.breadcrumbs = breadcrumbs;
        },
        onSave() {
            this.confirmDialogIsOpen = true;
            this.$emit('openConfirmDialog');
        },
        onCloseModal() {
            this.confirmDialogIsOpen = false;
        },
        onConfirmModal() {
            this.$emit('onSave');
            this.onCloseModal();
        },
    },
};
</script>
