<template>
    <ViewTemplate
        title="Convo topics"
        :loading-flag="LoadingFlag.ConvoGet"
        :loading-dialog="loadingOnSave"
        :save-button-disabled="saveButtonDisabled"
        save-button-label="save topics"
        @onSave="onSave"
    >
        <ErrorMessage v-if="hasErrors" size="large">
            Incomplete fields
            <ul class="m-t-1">
                <li v-for="convo in pageConvoErrors" :key="convo.id" class="convo-error">
                    {{ convo.name }}
                </li>
            </ul>
        </ErrorMessage>
        <FormGroup
            v-if="$options.queryFormFields"
            title="Filter"
            :form-fields="$options.queryFormFields"
            @input="onFilter"
        />

        <div class="flex">
            <Checkbox
                id="select-all"
                v-model="selectAll"
                class="m-l-1 m-t-auto"
                label="Select all"
                @input="onSelectAll"
            />
            <div class="m-l-auto">
                <div class="m-b-1">Bulk action</div>
                <SearchTopic
                    v-model="bulkTopics"
                    :topic-search-result="topicSearchResult"
                    @query="onSearchTopic"
                />
                <Button
                    button-style="secondary"
                    size="small"
                    @click="onConvoTopicsBulk({ addedTopics: bulkTopics })"
                >
                    Bulk Add
                </Button>
                <Button
                    button-style="secondary"
                    size="small"
                    @click="onConvoTopicsBulk({ removedTopics: bulkTopics })"
                >
                    Bulk Remove
                </Button>
            </div>
        </div>

        <Datatable
            :data="convoListView.convoList"
            :columns="[
                { header: 'CONVO', value: 'checkbox', type: 'slot', width: '450px' },
                { header: 'CHANNEL', value: 'channelName', width: '200px' },
                {
                    header: 'TOPICS',
                    value: 'topics',
                    type: 'slot',
                },
            ]"
        >
            <template v-slot:checkbox="{ item }">
                <Checkbox
                    :id="`row-${item.id}`"
                    class="m-l-1 m-r-1"
                    :has-margin-bottom="false"
                    :label="item.name"
                    :value="!!checkboxValues[item.id]"
                    @input="onCheckRow(item.id, $event)"
                />
            </template>
            <template v-slot:channel="{ item }">
                {{ item.name }}
            </template>
            <template v-slot:topics="{ item }">
                <div class="m-t-1 m-b-1 m-r-1">
                    <SearchTopic
                        :key="item.id"
                        :value="item.topics"
                        :topic-search-result="topicSearchResult"
                        :errors="convoErrors[item.id]"
                        :max-topics="$options.maxTopics"
                        @query="onSearchTopic"
                        @input="onTopic($event, item)"
                    />
                </div>
            </template>
        </Datatable>
        <Paging
            :total="convoListView.convoList.total"
            :size="convoListView.convoList.size"
            :page="convoListView.convoList.page"
            @pageSelected="selectPage"
        />
        <Dialog
            show-confirm-button
            confirm-button-text="Discard changes"
            close-button-text="Continue Editing"
            :is-dialog-visible="leaveDialogIsOpen"
            @onClose="leaveDialogOnConfirm"
            @onClickOutside="leaveDialogOnConfirm"
            @closeOnEscapeEvent="leaveDialogOnConfirm"
            @confirmButtonOnClick="leaveDialogOnClose"
        >
            <template #header>There are unsaved changes made.</template>
            <template #body>Continue editing to save progress.</template>
        </Dialog>
        <template v-if="updatedTopics.length" #dialog-confirm-body>
            Do you want to save these changes?
            <div v-for="convo in updatedTopics" :key="convo.id" class="m-t-1">
                <strong>
                    {{ convo.name }}
                </strong>
                <ul v-if="convo.addedTopics.length">
                    <li
                        v-for="topic in convo.addedTopics"
                        :key="topic.id"
                        class="text-sm convo-topic convo-topic--added"
                    >
                        <Icon name="numeric-plus" class="icon" />{{ topic.name }}
                    </li>
                </ul>
                <ul v-if="convo.removedTopics.length">
                    <li
                        v-for="topic in convo.removedTopics"
                        :key="topic.id"
                        class="text-sm convo-topic convo-topic--removed"
                    >
                        <Icon name="numeric-minus" class="icon" /> {{ topic.name }}
                    </li>
                </ul>
            </div>
        </template>
    </ViewTemplate>
</template>

<script>
import { ContentClient } from '@/api/content.ts';
import { mapGetters, mapActions } from 'vuex';
import { LoadingFlag } from '@/store/enums';
import Datatable from '@/components/Datatable';
import Checkbox from '@/components/forms/Checkbox.vue';
import FormGroup from '@/components/forms/FormGroup';
import SearchTopic from '@/components/forms/SearchTopics.vue';
import ViewTemplate from '@/components/ViewTemplate';
import ErrorMessage from '@/components/ui/ErrorMessage';
import Paging from '@/components/Paging';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { TopicClient } from '@/api/topic';
import LeavingDialogMixin from '@/mixins/leavingDialogMixin';
import Dialog from '@/components/ui/Dialog';

const convoQueryOptions = {
    type: -1,
    status: -1,
    chatGroup: -1,
    size: 20,
    setRedirectUrl: false,
};

export default {
    name: 'ConvoListTopics',
    components: {
        Datatable,
        Checkbox,
        SearchTopic,
        FormGroup,
        ViewTemplate,
        Button,
        Icon,
        Paging,
        Dialog,
        ErrorMessage,
    },
    mixins: [LeavingDialogMixin],
    data() {
        return {
            loadingOnSave: false,
            errors: false,
            checkboxValues: {},
            convoQueryOptions,
            LoadingFlag,
            selectAll: false,
            bulkTopics: [],
            topicSearchResult: [],
            convoErrors: {},
        };
    },
    computed: {
        ...mapGetters('convoList', ['convoListView', 'updatedTopics', 'updatedConvosId']),
        ...mapGetters('channel', ['channelListView']),
        // Do not modify the name. It is used in LeavingDialogMixin
        hasUnsavedChanges() {
            return !!this.updatedTopics.length;
        },
        saveButtonDisabled() {
            return !this.hasUnsavedChanges || this.hasErrors;
        },
        updatedConvos() {
            return (
                this.convoListView.convoList?.items?.filter((convo) =>
                    this.updatedConvosId.includes(convo.id),
                ) || []
            );
        },
        pageConvoErrors() {
            return this.updatedConvos.filter(
                (convo) => !convo.topics.length || convo.topics.length > this.$options.maxTopics,
            );
        },
        hasErrors() {
            return !!this.pageConvoErrors.length;
        },
    },
    watch: {
        pageConvoErrors(newErrors) {
            const errors = {};
            newErrors.forEach((convo) => {
                errors[convo.id] = [];

                if (!convo.topics.length) {
                    errors[convo.id].push('Topics are required');
                }
                if (convo.topics.length > this.$options.maxTopics) {
                    errors[convo.id].push(`Max. ${this.$options.maxTopics} topics`);
                }
            });
            this.convoErrors = errors;
        },
    },

    created() {
        this.$options.maxTopics = 5;
        this.$options.queryFormFields = [
            {
                name: 'search',
                label: 'Convo',
                placeholder: 'Search for a convo',
                icon: 'magnifying-glass',
            },
            {
                name: 'channel',
                label: 'Channel',
                type: 'AutoComplete',
                keyItem: 'name',
                query: this.onSearchChannel,
                placeholder: 'Search for a channel',
                showClearIcon: true,
            },
            {
                name: 'includeTopics',
                type: 'SearchTopics',
                label: 'Include topics',
                query: this.onSearchFilterTopic,
            },
            {
                name: 'excludeTopics',
                type: 'SearchTopics',
                label: 'Exclude topics',
                query: this.onSearchFilterTopic,
            },
        ];
    },
    async mounted() {
        this.convoQueryOptions.q = '';
        await this.getConvosPage();
    },
    methods: {
        ...mapActions('convoList', [
            'getConvos',
            'convoBulkActionTopics',
            'updateConvoTopics',
            'resetUpdateConvosTopics',
        ]),
        ...mapActions('channel', ['getChannels']),
        async onSearchTopic(query) {
            this.topicSearchResult = [];
            const topics = await new TopicClient().fetch({
                namespace: 'global',
                q: query,
                size: 15,
            });
            this.topicSearchResult = topics?.items || [];
        },
        async onSearchFilterTopic(query) {
            const topics = await new TopicClient().fetch({
                namespace: 'global',
                q: query,
                size: 15,
            });
            return topics?.items.filter((item) => item.id) || [];
        },
        async onSearchChannel(query) {
            if (!query) {
                this.convoQueryOptions.channelCode = '';
                this.getConvosPage();
            }
            await this.getChannels({
                q: query,
                includeInactive: true,
            });
            return this.channelListView.channelList?.items || [];
        },

        onFilter(ev) {
            this.convoQueryOptions.q = ev.search;
            this.convoQueryOptions.channelCode = ev.channel?.code;
            this.convoQueryOptions.includeTopics = ev.includeTopics
                ?.map((topic) => topic.id)
                .join(',');
            this.convoQueryOptions.excludeTopics = ev.excludeTopics
                ?.map((topic) => topic.id)
                .join(',');
            this.getConvosPage();
        },

        onCheckRow(id, value) {
            this.checkboxValues = { ...this.checkboxValues, [id]: value };
            this.selectAll = false;
        },

        onSelectAll(value) {
            const { items } = this.convoListView.convoList;
            let selectedItems = {};
            items?.forEach(({ id }) => {
                selectedItems = { ...selectedItems, [id]: value };
            });
            this.checkboxValues = selectedItems;
        },
        async getConvosPage() {
            await this.getConvos(this.convoQueryOptions);
            this.selectAll = false;
            this.resetUpdateConvosTopics();
        },
        selectPage(event, page) {
            this.leavingDialogMixin_leaveDialog((isAllowed) => {
                if (isAllowed) {
                    this.convoQueryOptions.page = page;
                    this.getConvosPage();
                }
            });
        },
        onTopic(topics, convo) {
            this.updateConvoTopics({ convo, topics });
            this.checkboxValues[convo.id] = false;
        },
        async onSave() {
            this.loadingOnSave = true;
            const convoTopics = this.updatedConvos.map((convo) => ({
                id: convo.id,
                topics: convo.topics,
            }));
            await ContentClient.updateConvosTopics({ convoTopics });
            this.resetUpdateConvosTopics();
            this.loadingOnSave = false;
        },
        onConvoTopicsBulk({ addedTopics, removedTopics }) {
            Object.entries(this.checkboxValues).forEach(([convoId, isChecked]) => {
                if (isChecked) {
                    this.convoBulkActionTopics({
                        convoId: Number(convoId),
                        addedTopics,
                        removedTopics,
                    });
                }
            });
            if (Object.entries(this.checkboxValues).length) {
                this.bulkTopics = [];
                this.checkboxValues = {};
                this.selectAll = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    color: currentColor;
    margin-right: 0.5rem;
}
.convo-topic {
    display: flex;
    align-items: center;
    &--added {
        color: $color-primary;
    }
    &--removed {
        color: $color-error;
    }
}
.convo-error {
    margin-bottom: 0.5rem;
    font-size: $font-size-sm;
}
</style>
