<template>
    <section class="form-group-wrapper">
        <h2 v-if="title" class="form-group-title">
            {{ title }}
        </h2>
        <div class="form-group">
            <div
                v-for="{
                    id,
                    type,
                    name,
                    label,
                    placeholder,
                    icon,
                    inputType,
                    query,
                    keyItem,
                    showClearIcon,
                } in formFields"
                :key="id"
                class="form-group-input"
            >
                <AutoComplete
                    v-if="type === 'AutoComplete'"
                    :id="id || name"
                    :value="
                        model[id || name] ? model[id || name][keyItem] || model[id || name] : ''
                    "
                    :name="label || name"
                    :label="label"
                    :placeholder="placeholder"
                    :items="queryResults[id || name]"
                    :is-async="!!onQuery"
                    :show-clear-icon="showClearIcon"
                    @query="onQuery(id || name, type, query, $event)"
                    @input="onAutocompleteInput($event, id || name)"
                    @clear="onAutocompleteInput(null, id || name)"
                >
                    <template v-slot:default="{ result }">
                        {{ result[keyItem] || result }}
                    </template>
                </AutoComplete>
                <SearchTopics
                    v-else-if="type === 'SearchTopics'"
                    :id="id || name"
                    v-model="model[id || name]"
                    :type="inputType || 'text'"
                    :name="label || name"
                    :label="label"
                    :placeholder="placeholder"
                    :default-icon="icon"
                    :topic-search-result="queryResults[id || name]"
                    @query="onQuery(id || name, type, query, $event)"
                    @input="updateForm"
                />
                <Input
                    v-else
                    :id="id || name"
                    v-model="model[id || name]"
                    :type="inputType || 'text'"
                    :name="label || name"
                    :label="label"
                    :placeholder="placeholder"
                    :default-icon="icon"
                    @input="updateForm"
                />
            </div>
        </div>
    </section>
</template>

<script>
import Input from '@/components/forms/Input';
import SearchTopics from '@/components/forms/SearchTopics.vue';
import AutoComplete from '@/components/forms/AutoComplete';

export default {
    components: {
        Input,
        SearchTopics,
        AutoComplete,
    },
    props: {
        title: {
            type: String,
            default: undefined,
        },
        formFields: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            model: {},
            queryResults: {},
        };
    },
    created() {
        const initialQueryResults = {};
        this.formFields.forEach((input) => {
            initialQueryResults[input.id || input.name] = [];
        });
        this.queryResults = initialQueryResults;
    },
    methods: {
        onAutocompleteInput(item, id) {
            this.model = { ...this.model, [id]: item };
            this.updateForm();
        },
        updateForm() {
            this.$emit('input', this.model);
        },
        async onQuery(id, type, queryFunction, query) {
            if (type === 'AutoComplete' && !query) {
                this.model = { ...this.model, [id]: null };
            }

            if (typeof queryFunction === 'function') {
                const result = await queryFunction(query);
                this.queryResults = { ...this.queryResults, [id]: result };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.form-group-wrapper {
    margin: 1rem 0 2rem;
}

.form-group-title {
    font-weight: $font-weight-regular;
    font-size: $font-size-md;
    padding: 1rem 0.5rem;
}
.form-group {
    display: flex;
    flex-wrap: wrap;
    &-input {
        padding: 0.5rem;
        width: clamp(16rem, 25%, 40rem);
        margin-bottom: auto;
    }
}
</style>
