var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"form-group-wrapper"},[(_vm.title)?_c('h2',{staticClass:"form-group-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{staticClass:"form-group"},_vm._l((_vm.formFields),function(ref){
            var id = ref.id;
            var type = ref.type;
            var name = ref.name;
            var label = ref.label;
            var placeholder = ref.placeholder;
            var icon = ref.icon;
            var inputType = ref.inputType;
            var query = ref.query;
            var keyItem = ref.keyItem;
            var showClearIcon = ref.showClearIcon;
return _c('div',{key:id,staticClass:"form-group-input"},[(type === 'AutoComplete')?_c('AutoComplete',{attrs:{"id":id || name,"value":_vm.model[id || name] ? _vm.model[id || name][keyItem] || _vm.model[id || name] : '',"name":label || name,"label":label,"placeholder":placeholder,"items":_vm.queryResults[id || name],"is-async":!!_vm.onQuery,"show-clear-icon":showClearIcon},on:{"query":function($event){return _vm.onQuery(id || name, type, query, $event)},"input":function($event){return _vm.onAutocompleteInput($event, id || name)},"clear":function($event){return _vm.onAutocompleteInput(null, id || name)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var result = ref.result;
return [_vm._v(" "+_vm._s(result[keyItem] || result)+" ")]}}],null,true)}):(type === 'SearchTopics')?_c('SearchTopics',{attrs:{"id":id || name,"type":inputType || 'text',"name":label || name,"label":label,"placeholder":placeholder,"default-icon":icon,"topic-search-result":_vm.queryResults[id || name]},on:{"query":function($event){return _vm.onQuery(id || name, type, query, $event)},"input":_vm.updateForm},model:{value:(_vm.model[id || name]),callback:function ($$v) {_vm.$set(_vm.model, id || name, $$v)},expression:"model[id || name]"}}):_c('Input',{attrs:{"id":id || name,"type":inputType || 'text',"name":label || name,"label":label,"placeholder":placeholder,"default-icon":icon},on:{"input":_vm.updateForm},model:{value:(_vm.model[id || name]),callback:function ($$v) {_vm.$set(_vm.model, id || name, $$v)},expression:"model[id || name]"}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }